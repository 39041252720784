import { Routes } from '@angular/router';
import { PermissionSectionChoices } from '@graphql/types';
import { billingRoutes } from './billing/routes';
import { documentsRoutes } from './documents/routes';
import { eduTrainingsRoutes } from './edu-trainings/routes';
import { employeesCertificatesRoutes } from './employees-certificates/routes';
import { employeesEmployeesRoutes } from './employees-employees/routes';
import { equipmentProtectiveGearRoutes } from './equipment-protective-gear/routes';
import { equipmentRevisionsRoutes } from './equipment-revisions/routes';
import { equipmentToolsRoutes } from './equipment-tools/routes';
import { equipmentVehicleColntrolRoutes } from './equipment-vehicle-control/routes';
import { equipmentVehiclesRoutes } from './equipment-vehicles/routes';
import { medicalExaminationsRoutes } from './medical-examinations/routes';
import { medicalInjuriesRoutes } from './medical-injuries/routes';
import { medicalOpinionsRoutes } from './medical-opinions/routes';
import { rootRoutes } from './root/routes';
import { rootRoutesSystem } from './root/routes-system';
import { settingsRootRoutes } from './settings-root/routes';
import { ROUTE_DEFINITION } from './shared/constants/route-definition.constant';
import { titleResolverFactory } from './shared/factories/title.factory';
import { isLoggedGuard } from './shared/guards/is-logged.guard';
import { moduleLimitsGuardFactory } from './shared/guards/module-limits.guard';
import { permissionCanReadGuardFactory } from './shared/guards/permission.guard';
import { redirectGuard } from './shared/guards/redirect.guard';
import { adminRoutes } from './superadmin/routes';
import { workplacesCorrectiveActionsRoutes } from './workplaces-corrective-actions/routes';
import { workplacesInspectionsRoutes } from './workplaces-inspections/routes';
import { workplacesworkplacesRoutes } from './workplaces-workplaces/routes';
import { equipmentInsuranceContractsRoutes } from '@app/equipment-insurance-contracts/routes';
import { BREADCRUMB_KEY } from '@ui';
import { breadcrumbFactory } from '@app/shared/factories/breadcrumb.factory';

export const routes: Routes = [
  {
    pathMatch: 'full',
    path: '',
    children: [],
    canActivate: [redirectGuard],
  },
  ...rootRoutes,
  ...rootRoutesSystem,
  {
    path: '',
    canActivate: [isLoggedGuard],
    loadComponent: () => import('./layout/layout-private/layout-private.component'),
    children: [
      // dashboard
      {
        path: ROUTE_DEFINITION.APP.DASHBOARD,
        title: titleResolverFactory({
          section: ['dashboard'],
        }),
        loadComponent: () => import('./dashboard/dashboard-general/dashboard-general.component'),
      },
      // education
      {
        pathMatch: 'full',
        path: ROUTE_DEFINITION.APP.EDU,
        redirectTo: `${ROUTE_DEFINITION.APP.EDU}/${ROUTE_DEFINITION.EDU.TRAININGS}`,
      },
      {
        path: `${ROUTE_DEFINITION.APP.EDU}/${ROUTE_DEFINITION.EDU.TRAININGS}`,
        canActivate: [
          moduleLimitsGuardFactory('educationModuleEnabled'),
          permissionCanReadGuardFactory(PermissionSectionChoices.Education),
        ],
        children: eduTrainingsRoutes,
      },
      // medical
      {
        pathMatch: 'full',
        path: ROUTE_DEFINITION.APP.MEDICAL,
        redirectTo: `${ROUTE_DEFINITION.APP.MEDICAL}/${ROUTE_DEFINITION.MEDICAL.INJURIES}`,
      },
      {
        path: `${ROUTE_DEFINITION.APP.MEDICAL}/${ROUTE_DEFINITION.MEDICAL.INJURIES}`,
        canActivate: [
          moduleLimitsGuardFactory('medicalModuleEnabled'),
          permissionCanReadGuardFactory(PermissionSectionChoices.Injuries),
        ],
        children: medicalInjuriesRoutes,
      },
      {
        path: `${ROUTE_DEFINITION.APP.MEDICAL}/${ROUTE_DEFINITION.MEDICAL.EXAMINATIONS}`,
        canActivate: [
          moduleLimitsGuardFactory('medicalModuleEnabled'),
          permissionCanReadGuardFactory(PermissionSectionChoices.MedicalExaminations),
        ],
        children: medicalExaminationsRoutes,
      },
      {
        path: `${ROUTE_DEFINITION.APP.MEDICAL}/${ROUTE_DEFINITION.MEDICAL.OPINIONS}`,
        canActivate: [
          moduleLimitsGuardFactory('medicalModuleEnabled'),
          permissionCanReadGuardFactory(PermissionSectionChoices.MedicalOpinions),
        ],
        children: medicalOpinionsRoutes,
      },
      // workplaces
      {
        pathMatch: 'full',
        path: ROUTE_DEFINITION.APP.WORKPLACES,
        redirectTo: `${ROUTE_DEFINITION.APP.WORKPLACES}/${ROUTE_DEFINITION.WORKPLACES.WORKPLACES}`,
      },
      {
        path: `${ROUTE_DEFINITION.APP.WORKPLACES}/${ROUTE_DEFINITION.WORKPLACES.CORRECTIVE_ACTIONS}`,
        canActivate: [
          moduleLimitsGuardFactory('workplaceModuleEnabled'),
          permissionCanReadGuardFactory(PermissionSectionChoices.WorkplaceCorrectiveActions),
        ],
        children: workplacesCorrectiveActionsRoutes,
      },
      {
        path: `${ROUTE_DEFINITION.APP.WORKPLACES}/${ROUTE_DEFINITION.WORKPLACES.INSPECTIONS}`,
        canActivate: [
          moduleLimitsGuardFactory('workplaceModuleEnabled'),
          permissionCanReadGuardFactory(PermissionSectionChoices.WorkplaceInspections),
        ],
        children: workplacesInspectionsRoutes,
      },
      {
        path: `${ROUTE_DEFINITION.APP.WORKPLACES}/${ROUTE_DEFINITION.WORKPLACES.WORKPLACES}`,
        canActivate: [
          moduleLimitsGuardFactory('workplaceModuleEnabled'),
          permissionCanReadGuardFactory(PermissionSectionChoices.Workplace),
        ],
        children: workplacesworkplacesRoutes,
      },
      // equipment
      {
        pathMatch: 'full',
        path: ROUTE_DEFINITION.APP.EQUIPMENT,
        redirectTo: `${ROUTE_DEFINITION.APP.EQUIPMENT}/${ROUTE_DEFINITION.EQUIPMENT.TOOLS}`,
      },
      {
        path: `${ROUTE_DEFINITION.APP.EQUIPMENT}/${ROUTE_DEFINITION.EQUIPMENT.PROTECTIVE_GEAR}`,
        canActivate: [
          moduleLimitsGuardFactory('equipmentModuleEnabled'),
          permissionCanReadGuardFactory(PermissionSectionChoices.ProtectiveGear),
        ],
        children: equipmentProtectiveGearRoutes,
      },
      {
        path: `${ROUTE_DEFINITION.APP.EQUIPMENT}/${ROUTE_DEFINITION.EQUIPMENT.REVISIONS}`,
        canActivate: [
          moduleLimitsGuardFactory('equipmentModuleEnabled'),
          permissionCanReadGuardFactory(PermissionSectionChoices.Controls),
        ],
        children: equipmentRevisionsRoutes,
      },
      {
        path: `${ROUTE_DEFINITION.APP.EQUIPMENT}/${ROUTE_DEFINITION.EQUIPMENT.TOOLS}`,
        canActivate: [
          moduleLimitsGuardFactory('equipmentModuleEnabled'),
          permissionCanReadGuardFactory(PermissionSectionChoices.Tools),
        ],
        children: equipmentToolsRoutes,
      },
      {
        path: `${ROUTE_DEFINITION.APP.EQUIPMENT}/${ROUTE_DEFINITION.EQUIPMENT.VEHICLES}`,
        canActivate: [
          moduleLimitsGuardFactory('equipmentModuleEnabled'),
          permissionCanReadGuardFactory(PermissionSectionChoices.Vehicles),
        ],
        children: equipmentVehiclesRoutes,
      },
      {
        path: `${ROUTE_DEFINITION.APP.EQUIPMENT}/${ROUTE_DEFINITION.EQUIPMENT.INSURANCE_CONTRACTS}`,
        canActivate: [
          moduleLimitsGuardFactory('equipmentModuleEnabled'),
          permissionCanReadGuardFactory(PermissionSectionChoices.Vehicles),
        ],
        children: equipmentInsuranceContractsRoutes,
      },
      {
        path: `${ROUTE_DEFINITION.APP.EQUIPMENT}/${ROUTE_DEFINITION.EQUIPMENT.VEHICLE_CONTROL}`,
        canActivate: [
          moduleLimitsGuardFactory('equipmentModuleEnabled'),
          permissionCanReadGuardFactory(PermissionSectionChoices.Vehicles),
        ], // todo check permission
        children: equipmentVehicleColntrolRoutes,
      },
      // employees
      {
        pathMatch: 'full',
        path: ROUTE_DEFINITION.APP.EMPLOYEES,
        redirectTo: `${ROUTE_DEFINITION.APP.EMPLOYEES}/${ROUTE_DEFINITION.EMPLOYEES.EMPLOYEES}`,
      },
      {
        path: `${ROUTE_DEFINITION.APP.EMPLOYEES}/${ROUTE_DEFINITION.EMPLOYEES.EMPLOYEES}`,
        canActivate: [permissionCanReadGuardFactory(PermissionSectionChoices.Employees)],
        children: employeesEmployeesRoutes,
      },
      {
        path: `${ROUTE_DEFINITION.APP.EMPLOYEES}/${ROUTE_DEFINITION.EMPLOYEES.CERTIFICATES}`,
        canActivate: [permissionCanReadGuardFactory(PermissionSectionChoices.Certifications)],
        children: employeesCertificatesRoutes,
      },
      // billing
      {
        path: ROUTE_DEFINITION.APP.BILLING,
        canActivate: [permissionCanReadGuardFactory(PermissionSectionChoices.AccountSettingsBilling)],
        children: billingRoutes,
      },
      // settings
      {
        path: ROUTE_DEFINITION.APP.SETTINGS,
        loadComponent: () => import('./settings-root/settings-root/settings-root.component'),
        children: settingsRootRoutes,
      },
      {
        path: ROUTE_DEFINITION.APP.TRANSLATOR,
        title: titleResolverFactory({
          section: ['translator'],
        }),
        loadComponent: () => import('./translator/translator.component'),
      },
      // documents
      {
        path: ROUTE_DEFINITION.APP.DOCUMENTS,
        canActivate: [permissionCanReadGuardFactory(PermissionSectionChoices.CompanyDocuments)],
        children: documentsRoutes,
      },
      // superadmin
      {
        path: ROUTE_DEFINITION.APP.ADMIN,
        // canActivate: [permissionCanReadGuardFactory(PermissionSectionChoices.Admin)], // TODO: Super admin permission is missing in API
        children: adminRoutes,
        data: {
          [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'superadmin' }),
        },
      },
      {
        path: ROUTE_DEFINITION.APP.RESTRICTED,
        children: adminRoutes,
        loadComponent: () => import('./module-limits/module-limits.component'),
      },
      // Other
      {
        path: '**',
        title: titleResolverFactory({
          section: ['not-found'],
        }),
        loadComponent: () => import('./not-found/not-found.component'),
      },
    ],
  },
  {
    path: '**',
    title: titleResolverFactory({
      section: ['not-found'],
    }),
    loadComponent: () => import('./not-found/not-found.component'),
  },
];
