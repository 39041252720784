import { Routes } from '@angular/router';
import { PageSection } from '@app/shared/dto/page.dto';
import { titleResolverFactory } from '@app/shared/factories/title.factory';
import { ROUTE_DEFINITION } from '@app/shared/constants/route-definition.constant';
import { BREADCRUMB_KEY } from '@ui';
import { breadcrumbFactory } from '@app/shared/factories/breadcrumb.factory';
import { BREADCRUMBS_GQL } from '@app/shared/constants/breadcrumb-gql.constants';

const tenantSection: PageSection[] = ['admin', 'companies'];
const jobPositionsSection: PageSection[] = ['admin', 'positions'];

const DEFAULT_ADMIN_JOB_BREADCRUMB_OPTIONS = {
  [BREADCRUMB_KEY]: breadcrumbFactory({
    title: 'id',
    gqlOptions: BREADCRUMBS_GQL['superadmin-job-position'],
  }),
};

const DEFAULT_ADMIN_MEDICAL_EXAMINATION_TYPE_BREADCRUMB_OPTIONS = {
  [BREADCRUMB_KEY]: breadcrumbFactory({
    title: 'id',
    gqlOptions: BREADCRUMBS_GQL['superadmin-medical-examination-type'],
  }),
};

const DEFAULT_ADMIN_CERTIFICATION_BREADCRUMB_OPTIONS = {
  [BREADCRUMB_KEY]: breadcrumbFactory({
    title: 'id',
    gqlOptions: BREADCRUMBS_GQL['superadmin-certification'],
  }),
};

const NULL_BREADCRUMB_DATA = {
  [BREADCRUMB_KEY]: null,
};

export const adminRoutes: Routes = [
  {
    path: '',
    title: titleResolverFactory({
      section: tenantSection,
      action: ['list'],
    }),
    data: {
      ...NULL_BREADCRUMB_DATA,
    },
    children: [
      {
        path: '',
        title: titleResolverFactory({
          section: tenantSection,
          action: ['list'],
        }),
        loadComponent: () => import('./superadmin-root/superadmin-root.component'),
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: ROUTE_DEFINITION.ADMIN.TENANT_LIST,
          },
          {
            path: ROUTE_DEFINITION.ADMIN.TENANT_LIST,
            loadComponent: () => import('./tenant-list/tenant-list.component'),
          },
          {
            path: ROUTE_DEFINITION.ADMIN.JOB_POSITIONS_CATALOGUE,
            loadComponent: () =>
              import('./job-positions-catalogue/job-positions-catalogue-list/job-positions-catalogue-list.component'),
          },
          {
            path: ROUTE_DEFINITION.ADMIN.MEDICAL_EXAMINATION_TYPES_CATALOGUE,
            loadComponent: () =>
              import(
                './admin-medical-examination-types/admin-medical-examination-types-list/admin-medical-examination-types-list.component'
              ),
          },
          {
            path: ROUTE_DEFINITION.ADMIN.CERTIFICATIONS_CATALOGUE,
            loadComponent: () =>
              import(
                './admin-certifications-catalogue/admin-certifications-catalogue-list/admin-certifications-catalogue-list.component'
              ),
          },
        ],
      },
    ],
  },
  {
    path: ROUTE_DEFINITION.ADMIN.JOB_POSITIONS_CATALOGUE,
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'job-positions' }),
    },
    children: [
      {
        path: ':id',
        loadComponent: () =>
          import('./job-positions-catalogue/job-positions-catalogue-detail/job-positions-catalogue-detail.component'),
        data: {
          ...NULL_BREADCRUMB_DATA,
        },
        children: [
          {
            path: '',
            data: {
              ...DEFAULT_ADMIN_JOB_BREADCRUMB_OPTIONS,
            },
            children: [
              {
                path: '',
                title: titleResolverFactory({
                  section: jobPositionsSection,
                  action: ['professions'],
                }),
                data: {
                  ...NULL_BREADCRUMB_DATA,
                },
                loadComponent: () =>
                  import(
                    './job-positions-catalogue/job-positions-catalogue-professions/job-positions-catalogue-professions.component'
                  ),
              },
              {
                path: ROUTE_DEFINITION.ROOT.RISKS,
                title: titleResolverFactory({
                  section: jobPositionsSection,
                  action: ['risks'],
                }),
                data: {
                  ...NULL_BREADCRUMB_DATA,
                },
                loadComponent: () =>
                  import(
                    './job-positions-catalogue/job-positions-catalogue-risks/job-positions-catalogue-risks.component'
                  ),
              },
              {
                path: ROUTE_DEFINITION.ROOT.MEDICAL_EXAMINATION_TYPE,
                title: titleResolverFactory({
                  section: jobPositionsSection,
                  action: ['medical-examination-type'],
                }),
                data: {
                  ...NULL_BREADCRUMB_DATA,
                },
                loadComponent: () =>
                  import(
                    './job-positions-catalogue/job-positions-catalogue-medical-examination-type-list/job-positions-catalogue-medical-examination-type-list.component'
                  ),
              },
              {
                path: ROUTE_DEFINITION.ROOT.HAZARDS,
                title: titleResolverFactory({
                  section: jobPositionsSection,
                  action: ['hazards'],
                }),
                data: {
                  ...NULL_BREADCRUMB_DATA,
                },
                loadComponent: () =>
                  import(
                    './job-positions-catalogue/job-positions-catalogue-hazards/job-positions-catalogue-hazards.component'
                  ),
              },
              {
                path: ROUTE_DEFINITION.ROOT.RISK_FACTORS,
                title: titleResolverFactory({
                  section: jobPositionsSection,
                  action: ['risk-factors'],
                }),
                data: {
                  ...NULL_BREADCRUMB_DATA,
                },
                loadComponent: () =>
                  import(
                    './job-positions-catalogue/job-positions-catalogue-risk-factors/job-positions-catalogue-risk-factors.component'
                  ),
              },
              {
                path: ROUTE_DEFINITION.ROOT.MEDICAL_EXAMINATIONS,
                title: titleResolverFactory({
                  section: jobPositionsSection,
                  action: ['medical-examinations'],
                }),
                data: {
                  ...NULL_BREADCRUMB_DATA,
                },
                loadComponent: () =>
                  import(
                    './job-positions-catalogue/job-positions-catalogue-medical-examinations/job-positions-catalogue-medical-examinations.component'
                  ),
              },
              {
                path: ROUTE_DEFINITION.ROOT.CERTIFICATION,
                title: titleResolverFactory({
                  section: jobPositionsSection,
                  action: ['certification'],
                }),
                data: {
                  ...NULL_BREADCRUMB_DATA,
                },
                loadComponent: () =>
                  import(
                    './job-positions-catalogue/job-positions-catalogue-certification-list/job-positions-catalogue-certification-list.component'
                  ),
              },
              {
                path: ROUTE_DEFINITION.ROOT.THREATS,
                title: titleResolverFactory({
                  section: jobPositionsSection,
                  action: ['threats'],
                }),
                data: {
                  ...NULL_BREADCRUMB_DATA,
                },
                loadComponent: () =>
                  import(
                    './job-positions-catalogue/job-positions-catalogue-threats/job-positions-catalogue-threats.component'
                  ),
              },
              {
                path: ROUTE_DEFINITION.ROOT.PROTECTIVE_GEAR,
                title: titleResolverFactory({
                  section: jobPositionsSection,
                  action: ['protective-gear'],
                }),
                data: {
                  ...NULL_BREADCRUMB_DATA,
                },
                loadComponent: () =>
                  import(
                    './job-positions-catalogue/job-positions-catalogue-protective-gear-list/job-positions-catalogue-protective-gear-list.component'
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: ROUTE_DEFINITION.ADMIN.MEDICAL_EXAMINATION_TYPES_CATALOGUE,
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'medical-examination-types' }),
    },
    children: [
      {
        path: ':id',
        data: {
          ...NULL_BREADCRUMB_DATA,
        },
        children: [
          {
            path: '',
            data: {
              ...DEFAULT_ADMIN_MEDICAL_EXAMINATION_TYPE_BREADCRUMB_OPTIONS,
            },
            loadComponent: () =>
              import(
                './admin-medical-examination-types/admin-medical-examination-type-detail/admin-medical-examination-type-detail.component'
              ),
          },
        ],
      },
    ],
  },
  {
    path: ROUTE_DEFINITION.ADMIN.CERTIFICATIONS_CATALOGUE,
    data: {
      [BREADCRUMB_KEY]: breadcrumbFactory({ title: 'certifications' }),
    },
    children: [
      {
        path: ':id',
        data: {
          ...NULL_BREADCRUMB_DATA,
        },
        children: [
          {
            path: '',
            data: {
              ...DEFAULT_ADMIN_CERTIFICATION_BREADCRUMB_OPTIONS,
            },
            loadComponent: () =>
              import(
                './admin-certifications-catalogue/admin-certifications-catalogue-detail/admin-certifications-catalogue-detail.component'
              ),
          },
        ],
      },
    ],
  },
];
